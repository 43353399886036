@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: "jetbrainsmono";
  src: url("/public/JetBrainsMono-Regular.ttf");
}

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600&display=swap');

* {
  margin: 0;
}

.App {
  background-color: #000000;
  width: 100%;
  min-height: 100vh;
  /* font-family: 'Montserrat'; */
  font-family: Inter;
  overflow-x: hidden;
  color: white;
}

.main-content {
  height: 100%;
  display: flex;
  max-width: 1200px;

  flex-direction: column;
  text-align: left;
}

h1 {
  max-width: 60%;
  height: 90%;
  min-width: 300px;
  margin: 0 0 0 2rem;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 2rem;
  font-size: 4rem;
  color: #eee;
  height: 100vh;
  z-index: 1;
}

canvas {
  position: absolute;
  color: red;
}

h5 {
  font-size: 1rem;
  margin-top: 1rem;
  color: white;
  display: none;
}

h3 {
  font-size: 2rem;
  padding: 0;
  margin: 0 0 1rem;
  color: #e879e9;
  font-family: jetbrainsmono;
  font-weight: 200;
}

h4 {
  color: #8892B0;
  font-size: 1.3rem;
  max-width: 80%;
  padding: 0;
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
}

.projects-button {
  width: 25%;
  margin-top: 1rem;
  border: none;
  height: 2.5rem;
  background-color: white;
  color: black;
  font-family: 'Inter';
  border-radius: 5px;
  font-weight: 700;
  transition: 0.5s;
}
.projects-button:hover {
  background-color: #e879e9;
  color: white;
  cursor: pointer;
}

.project-container {
  height: 30vh;
  width: 30vw;
  border-radius: 5px;
  border: 1px solid white;
  background-image: url('/public/azol.png');
  background-size: cover;
  transition: 0.5s;
  margin-top: 2rem;
}
.project-container:hover {
  border: 1px solid #e879e9;
  cursor: pointer;
}

.projects-title {
  margin-bottom: 0;
}

.back {
  position: absolute;
  z-index: 999;
  top: 1rem;
  left: 1rem;
}
.back:hover {
  cursor: pointer;
}

.overlay {
  position: relative;
  background: rgba(0, 0, 0, 0.7); 
  opacity: 0;
  transition: 0.3s;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.project-container:hover .overlay {
  opacity: 1;
}

.github {
  position: absolute;
  bottom: 1rem;
  height: 30px;
  width: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 999;
}
.github:hover {
  cursor: pointer;
}

.foward {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.foward:hover {
  cursor: pointer;
}


@media screen and (max-width: 500px) {
  h1 {
    font-size: 1.7rem;
    max-width: 90%;
    margin: 0 0 0 0.5rem;
  }

  h3 {
    font-size: 1rem;
  }

  h4 {
    font-size: 0.9rem;
    max-width: 95%;
  }

  h5 {
    display: block;
  }
}